<template>
  <div class="edit-schedule-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Schedule' }"
        >檔期管理</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'EditSchedule' }"
        >編輯檔期</el-breadcrumb-item
      >
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="scheduleForm"
        :model="schedule_form"
        :rules="schedule_rules"
        label-position="top"
      >
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input
            v-model="schedule_form.name_zh"
            maxlength="256"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input
            v-model="schedule_form.name_en"
            maxlength="256"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="開展日期" prop="start_date">
          <el-date-picker
            v-model="schedule_form.start_date"
            type="datetime"
            placeholder="請選擇開展日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="閉展日期" prop="end_date">
          <el-date-picker
            v-model="schedule_form.end_date"
            type="datetime"
            placeholder="請選擇閉展日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="展區" prop="schedule_location_id">
          <el-select v-model="schedule_form.schedule_location_id">
            <el-option
              v-for="{ schedule_location_id, name_zh } in scheduleLocations"
              :key="schedule_location_id"
              :value="schedule_location_id"
              :label="name_zh"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="啟用" prop="active">
          <el-radio v-model="schedule_form.active" :border="true" :label="true"
            >啟用</el-radio
          >
          <el-radio v-model="schedule_form.active" :border="true" :label="false"
            >停用</el-radio
          >
        </el-form-item>
        <el-form-item label="CF影片連結" prop="cf_video_links">
          <el-input
            v-for="(_, index) in schedule_form.cf_video_links"
            class="cf-input"
            :class="[schedule_form.cf_video_links.length > 1 ? 'gap' : '']"
            v-model="schedule_form.cf_video_links[index]"
            :key="index"
          >
            <template #suffix>
              <div class="cf-suffix-button-group">
                <el-button
                  type="success"
                  class="cf-button"
                  icon="el-icon-plus"
                  circle
                  @click="handleAddCF"
                ></el-button>
                <el-button
                  type="danger"
                  class="cf-button"
                  icon="el-icon-minus"
                  circle
                  @click="handleRemoveCF"
                ></el-button>
              </div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item label="備註" prop="note">
          <el-input
            type="textarea"
            v-model="schedule_form.note"
            maxlength="512"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { updateSchedule, getSchedule } from "@/api/schedule";
import { getScheduleLocations } from "@/api/schedule-location";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);

export default {
  name: "EditSchedule",
  data() {
    const validateDate = (rule, value, callback) => {
      if (
        dayjs(this.schedule_form.start_date).isSameOrAfter(
          dayjs(this.schedule_form.end_date)
        )
      ) {
        callback(new Error("閉展日期需大於開展日期"));
      }

      callback();
    };
    return {
      schedule_form: {
        schedule_id: 0,
        name_zh: "",
        name_en: "",
        start_date: "",
        end_date: "",
        note: "",
        active: true,
        cf_video_links: [""],
        schedule_location_id: "",
      },
      schedule_rules: {
        name_zh: [
          { required: true, message: "請輸入中文名稱", trigger: "blur" },
          { max: 256, message: "長度過長", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請輸入英文名稱", trigger: "blur" },
          { max: 256, message: "長度過長", trigger: "blur" },
        ],
        start_date: [
          { required: true, message: "請輸入開展日期", trigger: "blur" },
        ],
        end_date: [
          { required: true, message: "請輸入閉展日期", trigger: "blur" },
          { validator: validateDate, trigger: "blur" },
        ],
        note: [{ max: 512, message: "長度過長", trigger: "blur" }],
        active: [
          { required: true, message: "請選擇是否啟用", trigger: "blur" },
        ],
      },
      scheduleLocations: [],
    };
  },
  async created() {
    await this.handleGetScheduleLocations();
    await this.handleGetSchedule();
  },
  methods: {
    async handleGetScheduleLocations() {
      this.scheduleLocations = await getScheduleLocations();

      if (this.scheduleLocations.length === 0) {
        await this.$alert("你尚未新增任何展區，系統將幫你自動跳轉", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.$router.push({ name: "CreateScheduleLocation" });
          },
        });
      }
    },
    async handleGetSchedule() {
      try {
        const res = await getSchedule(+this.$route.params.id, false);

        Object.keys(this.schedule_form).forEach((key) => {
          if (res[key]) {
            if (key === "cf_video_links" && res[key].length === 0) return;
            this.schedule_form[key] = res[key];
          } else {
            if (key === "schedule_location_id") {
              if (res.schedule_location) {
                this.schedule_form.schedule_location_id =
                  res.schedule_location.schedule_location_id;
              } else {
                this.schedule_form.schedule_location_id =
                  this.scheduleLocations[0].schedule_location_id;
              }
            }
          }
        });
      } catch (error) {
        await this.$alert("資料有誤，系統將強制跳轉頁面", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.handleRedirect();
          },
        });
      }
    },
    handleSubmit() {
      this.$refs.scheduleForm.validate(async (valid) => {
        if (valid) {
          const { schedule_id, ...rest } = this.schedule_form;
          const cloneSchedule = JSON.parse(JSON.stringify(rest));

          cloneSchedule.cf_video_links = cloneSchedule.cf_video_links
            .map((link) => link.trim())
            .filter((link) => link.length > 0);

          await updateSchedule(schedule_id, cloneSchedule);

          this.$message({
            type: "success",
            message: "編輯成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "Schedule" });
    },
    handleAddCF() {
      this.schedule_form.cf_video_links.push("");
    },
    handleRemoveCF(index) {
      if (this.schedule_form.cf_video_links.length === 1) return;

      this.schedule_form.cf_video_links.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-schedule-block {
  .cf-input {
    &.gap {
      margin-bottom: 12px;
    }

    ::v-deep .el-input--suffix .el-input__inner {
      padding-right: 84px;
    }

    .cf-suffix-button-group {
      display: flex;
      align-items: center;
      margin-right: 12px;

      .cf-button {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding: 0;
        font-size: 12px;
      }
    }
  }
}
</style>
